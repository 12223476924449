"use client";
import { Button, Result } from "antd";
import { useRouter } from "next/navigation";
import { useCallback } from "react";

export default function NotFound() {
  const router = useRouter();
  const backToHome = useCallback(() => {
    router.replace("/");
  }, []);
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={backToHome} type="primary">
            Back Home
          </Button>
        }
      />
    </div>
  );
}
